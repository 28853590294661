/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkout_text } from '../../../constant/Translation/Cart_text';
import { authSelector } from '../../../store/feature/authSlice';
import {
  cartList,
  cartSelector,
  shippingMethods,
} from '../../../store/feature/cartSlice';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';

const ExternalShipmentMethod = ({
  shipment_methods,
  selectedShipmentMethod,
  setSelectedShipmentMethod,

  styles,
}) => {
  const dispatch = useDispatch();
  const selectShipmentMethod = (method) => {
    setSelectedShipmentMethod(method);
  };

  // initial setup
  useEffect(() => {
    if (shipment_methods?.length > 0) {
      selectShipmentMethod(shipment_methods[0]);
    }
  }, [shipment_methods]);

  return shipment_methods ? (
    shipment_methods?.length > 0 && (
      <div
        style={get_dynamic_styles(styles?.main_styles)}
        className="   overflow-y-auto"
      >
        <p className="text-primary text-xl leading-6 font-medium ">
          {Checkout_text.cart.shipment_preference}
        </p>
        <div className=" mt-5 flex items-center flex-wrap gap-3">
          {shipment_methods.map((method) => {
            return (
              <button
                className={
                  selectedShipmentMethod?.id !== method.id
                    ? 'w-full min-h-[20px] px-2 py-4 border border-gray-300 flex items-start justify-start rounded-button    transition duration-700    hover:border-primary hover:text-primary gap-3 text-start'
                    : ' w-full  min-h-[20px] px-2 py-4 border  flex items-start justify-start   border-transparent bg-primary text-white rounded-button cursor-pointer  ring-2 ring-primary ring-offset-2  gap-3 text-start'
                }
                key={method.id}
                onClick={() => selectShipmentMethod(method)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={[
                    'h-5 w-5  mt-0.5   ',
                    selectedShipmentMethod?.id === method.id
                      ? ''
                      : 'text-gray-500',
                  ].join(' ')}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <div className=" flex flex-grow flex-wrap  gap-1 justify-between items-start ">
                  <span className=" text-base   font-semibold  ">
                    {method.name}
                  </span>
                  <span className=" text-base   font-normal ">
                    Price: {method?.price}
                  </span>
                </div>
              </button>
            );
          })}
        </div>

        {selectedShipmentMethod && selectedShipmentMethod?.note && (
          <div
            className="mt-2 bg-green-100 border-t-2 border-green-500 rounded-b text-green-900 px-4 py-2 shadow-md"
            role="alert"
          >
            <div className="flex items-start">
              <svg
                className="fill-current h-4 w-4 text-green-500 mr-4 mt-0.5 flex-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
              </svg>

              <div className="flex-grow">
                <p className="text-sm">{selectedShipmentMethod?.note}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  ) : (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div className="  animate-pulse border bg-[#3B3269] bg-opacity-[20%] shadow rounded-card   p-4   w-full mx-auto">
        <div className=" flex space-x-4">
          <div className="flex-1 space-y-4 py-1">
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-1/4"></div>
            <div className=" flex items-center justify-start gap-3">
              <div className="h-10  w-20 bg-[#3B3269] bg-opacity-[20%] rounded"></div>
              <div className="h-10 w-20 bg-[#3B3269] bg-opacity-[20%] rounded-card   "></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExternalShipmentMethod;
