import React, { useEffect, useState } from 'react';
import SubscriptionCard from './SubscriptionCard';
import { useAppDispatch, useAppSelector } from '../../hooks/Redux';
import {
  getSubscriptionList,
  subscribeBYMangoPay,
  SubscriptionSelector,
} from '../../store/feature/subscription/subscriptionSlice';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import SubscriptionCardsSkeleton from '../Shared/skeletonUI/SubscriptionCardsSkeleton';
import SubscriptionDetails from './SubscriptionDetails';
import { onOpenSidebar } from '../../store/feature/SidebarSlice';
import Cards from '../Cards/Cards';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { getCookie, getCookies } from 'cookies-next';
import { useRouter } from 'next/router';
import { base_url } from '../../constant/url';
import { authSelector } from '../../store/feature/authSlice';
import { decryptDataWithAES } from '../../constant/sdk_set';

const SubscriptionsList = ({ styles, data }) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { auth_key } = useAppSelector(authSelector);
  //
  const { subscription_products, isFetching } =
    useAppSelector(SubscriptionSelector);
  const [selected_id, setSelectedID] = useState(null);
  const [see_details_id, setSeeDetailsID] = useState(null);
  const account_id = getCookie(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`);
  const user_selected_currency = getCookie(
    `${TYPE_CONSTANT.DOMAIN}_default_currency`
  );
  //
  useEffect(() => {
    setSelectedID(null);
    if (account_id) {
      dispatch(getSubscriptionList({ account_id }));
    } else {
      dispatch(getSubscriptionList({ account_id: null }));
    }
  }, [account_id]);

  //
  const handle_pay_click = (selected_subscription, card_id) => {
    dispatch(
      // @ts-ignore
      subscribeBYMangoPay({
        subscription_info: {
          amount: selected_subscription?.amount,
          card_id: selected_card?.id,
          subscription_product_id: selected_subscription?.id,
          currency: selected_subscription?.currency?.code,
          card_id,
          account_id: getCookie(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`),
        },
        window_info: {
          color_depth: window.screen.colorDepth,
          screen_height: window.innerHeight,
          screen_width: window.innerWidth,
          time_zone_offset: new Date().getTimezoneOffset(),
        },
      })
    ).then((res) => {
      if (res?.payload?.redirect_url) {
        router.push(res?.payload?.redirect_url);
      }
    });
  };

  //
  const onStartBuyPlan = (e) => {
    const domain_details = JSON.parse(
      decryptDataWithAES(getCookie(`${TYPE_CONSTANT.DOMAIN}_domain_details`))
    );

    if (!account_id) {
      router.push('/a/create-account');
    } else {
      e.stopPropagation();
      const selected_subscription = subscription_products.find(
        (pr) => pr?.id == selected_id
      );

      if (selected_subscription?.type === 'stripe') {
        router.push(
          `${base_url()}/v1/subscriptions/subscribe/products/${
            selected_subscription?.id
          }?auth_key=${auth_key}&account_id=${account_id}&domain=${
            domain_details?.id
          }&currency=${user_selected_currency}`
        );
      } else {
        dispatch(
          onOpenSidebar({
            sidebar_id: 'buy-plan-sidepan',
            modal_section_styles:
              'fixed  ltr:right-0 rtl:left-0 bottom-0 lg:top-0 lg:my-auto   z-[60]  block flex items-center  justify-center  transform bg-white rounded shadow-lg   max-w-full md:max-w-4xl w-full   rounded-t-[40px]   lg:rounded-card  overflow-hidden',
            modal_inside_section_styles:
              'w-full   max-h-[80VH] md:max-h-full  h-full lg:scrollbar-thin   lg:scrollbar-track-transparent overflow-auto  lg:scrollbar-thumb-transparent  ',
            is_close_by_outer_click: true,

            sidebar_inside_component: (
              <Cards
                closeSidebar={() => dispatch(onCloseSidebar())}
                amount_formatted={
                  selected_subscription?.currency_amount?.formatted
                }
                type="subscription"
                onPayClick={(card_id) =>
                  handle_pay_click(selected_subscription, card_id)
                }
                currency={selected_subscription?.currency?.code}
              />
            ),
          })
        );
      }
    }
  };

  return (
    <div className="space-y-10" style={get_dynamic_styles(styles?.main_styles)}>
      {/* Header */}
      <div className=" space-y-3">
        {data?.title && (
          <h4 className=" text-base  font-semibold text-center">
            {get_data_by_current_language({
              data_list: data?.translated_data,
              key_name: 'title',
              default_data: data?.title,
            })}
          </h4>
        )}
        {data?.description && (
          <p className=" text-sm max-w-sm  mx-auto text-center text-gray-500 ">
            {get_data_by_current_language({
              data_list: data?.description,
              key_name: 'description',
              default_data: data?.description,
            })}
          </p>
        )}
      </div>

      {/*list  */}
      {!isFetching && (
        <div className="w-full flex items-start justify-center flex-wrap  gap-6">
          {subscription_products?.map((product) => (
            <SubscriptionCard
              key={product.id}
              product={product}
              selected_id={selected_id}
              setSelectedID={setSelectedID}
              see_details_id={see_details_id}
              setSeeDetailsID={setSeeDetailsID}
            />
          ))}
        </div>
      )}

      {/* Skeleton loading */}
      {isFetching && <SubscriptionCardsSkeleton />}

      {see_details_id && (
        <SubscriptionDetails
          subscription_id={see_details_id}
          key={see_details_id}
        />
      )}

      {/* button */}
      <button
        className="btn-primary-fill w-full"
        onClick={(e) => onStartBuyPlan(e)}
        disabled={!selected_id}
      >
        Upgrade Now
      </button>
    </div>
  );
};

export default SubscriptionsList;
